<template>
  <div id="invoice-view">
    <section>
      <b-row class="invoice-preview">
        <b-col></b-col>
        <b-col cols="12" xl="9">
          <supply-document
            id="invoice-document"
            :invoice-configuration="invoiceConfiguration"
            :loading="invoiceLoading"
            :marketplace-invoice="marketplaceInvoice"
            :supply="invoice"
            :title="$t('common.invoice')"
          />
        </b-col>
        <b-col></b-col>
      </b-row>
    </section>

    <div class="print-btn">
      <b-overlay :show="fullInvoicePdfLoading">
        <b-button id="supply-document-pdf-btn" @click="generateFullInvoicePdf()">
          <feather-icon icon="FileTextIcon" size="20" class="align-middle" />
          <span class="ml-25 align-middle"> {{ $t('action.print') }} </span>
        </b-button>
      </b-overlay>
    </div>
  </div>
</template>
<script>
import {fetchInvoiceRequest, printInvoice} from '@/request/globalApi/requests/invoiceRequests'

import SupplyDocument from '@/components/SupplyDocument.vue'
import SupplyDocumentPdf from '@/components/SupplyDocumentPdf.vue'
import OrderViewActionsManager from "@/views/order/OrderViewActionsManager.vue";

export default {
  name: 'InvoiceView',

  components: {
    OrderViewActionsManager,
    SupplyDocument,
  },

  data() {
    return {
      invoiceLoading: false,
      marketplaceInvoice: false,
      invoice: {},
      invoiceConfiguration: null,
      fullInvoicePdfLoading: false,
    }
  },

  mounted() {
    if (this.$route.params.id) {
      this.invoiceLoading = true
      fetchInvoiceRequest(this.$route.params.id)
        .then(response => {
          this.invoice = response.data.invoice
          this.invoiceConfiguration = response.data.invoiceConfiguration
          this.marketplaceInvoice = response.data.marketplaceInvoice
        })
        .finally(() => {
          this.invoiceLoading = false
        })
    }
  },
  methods: {
    generateFullInvoicePdf() {
      this.fullInvoicePdfLoading = true
      printInvoice(this.invoice.id).finally(() => {
        this.fullInvoicePdfLoading = false
      }).catch(() => {
        this.fullOrderPdfLoading = false
        this.toaster(this.$t('errors.pdf.cannot_generate_pdf'), 'danger', 'AlertTriangleIcon')
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';

.print-btn {
  position: fixed;
  bottom: 5%;
  right: 26px;
  z-index: 1031;

  .btn {
    box-shadow: 0 1px 20px 1px $primary !important;

    &:hover {
      box-shadow: none !important;
    }
  }
}
</style>
