import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'
import axios from "axios";

const END_POINT = 'api/invoice'

// QUOTE

export const fetchInvoicesRequest = params => globalApi.get(END_POINT, { params })

export const fetchInvoiceRequest = invoiceId => globalApi.get(`${END_POINT}/${invoiceId}`)

export const patchInvoiceRequest = (invoice, message) => globalApi.patch(`${END_POINT}/${invoice.id}`, invoice, { message })
// remove ??
export const deleteInvoiceRemoveRequest = (invoiceId, message) => fireDeleteAlert().then(
  isConfirmed => isConfirmed
      && globalApi.delete(`${END_POINT}/${invoiceId}/remove`, { message }),
)

// FILTERS

export const fetchInvoiceFiltersRequest = () => globalApi.get('/api/invoice-filters')
export const printInvoice = (invoiceId) => {
  const token = `Bearer ${localStorage.getItem('token')}` || ''
  const selectedOrganizationId = localStorage.getItem('selectedOrganization')
    && JSON.parse(localStorage.getItem('selectedOrganization')).id
  const lang = localStorage.getItem('lang')

  return axios.get(`${process.env.VUE_APP_API_BASE_URL}api/invoice/${invoiceId}/print`, {
    headers: { Accept: 'application/pdf', 'Content-Type': 'application/pdf', Authorization: token, selectedOrganizationId, lang },
    responseType: 'arraybuffer',
  }).then(response => {
    const blob = new Blob(
      [response.data],
      { type: 'application/pdf' },
    )
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    if (/iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
      link.download = `invoice-${invoiceId}.pdf`
    }

    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  })
}